body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Montserrat;
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  font-style: normal;
  src: local('Montserrat-Thin'), local('Montserrat-Thin'),
    url('./fonts/Montserrat/Montserrat-Thin.ttf') format('truetype'),
    url('./fonts/Montserrat/Montserrat-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  font-style: normal;
  src: local('Montserrat-ExtraLight'), local('Montserrat-ExtraLight'),
    url('./fonts/Montserrat/Montserrat-ExtraLight.ttf') format('truetype'),
    url('./fonts/Montserrat/Montserrat-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  font-style: normal;
  src: local('Montserrat-Light'), local('Montserrat-Light'),
    url('./fonts/Montserrat/Montserrat-Light.ttf') format('truetype'),
    url('./fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  src: local('Montserrat-Regular'), local('Montserrat'),
    url('./fonts/Montserrat/Montserrat-Regular.ttf') format('truetype'),
    url('./fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: normal;
  src: local('Montserrat-Medium'), local('Montserrat-Medium'),
    url('./fonts/Montserrat/Montserrat-Medium.ttf') format('truetype'),
    url('./fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  font-style: normal;
  src: local('Montserrat-SemiBold'), local('Montserrat-SemiBold'),
    url('./fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype'),
    url('./fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-style: normal;
  src: local('Montserrat-Bold'), local('Montserrat-Bold'),
    url('./fonts/Montserrat/Montserrat-Bold.ttf') format('truetype'),
    url('./fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  font-style: normal;
  src: local('Montserrat-ExtraBold'), local('Montserrat-ExtraBold'),
    url('./fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype'),
    url('./fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  font-style: normal;
  src: local('Montserrat-Black'), local('Montserrat-Black'),
    url('./fonts/Montserrat/Montserrat-Black.ttf') format('truetype'),
    url('./fonts/Montserrat/Montserrat-Black.ttf') format('truetype');
}