.page {
    height: 100vh;
    overflow: auto;
    display: flex;
    background-color: #3f7250;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    
    }
}

.sidebar {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 128px);
    width: 400px;
    padding: 64px;
    @media screen and (max-height: 768px) {
        padding: 20px !important;
        height: calc(100% - 40px) !important;
    }
    @media screen and (max-width: 768px) {
        /* display: none !important; */
        height: 70px !important;
        width: calc(100% - 32px) !important;
        padding: 16px !important;
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .logo {
            svg {
                width: 40px;
            }
            span {
                font-size: 24px;
            }
        }
        .sub-logo {
            font-size: 14px;
            line-height: normal;
        }
        .products, .info{
            display: none;
        }
    }
}

.content {
    background-color: #ffffff;
    height: calc(100% - 128px);
    overflow: auto;
    border-radius: 40px 0px 0px 40px;
	background-image: url('./images/background.png');
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: cover;
	/* padding: 64px; */
	display: flex;
	flex-direction: column;
	gap: 40px;
	width: 100%;
    @media screen and (max-width: 768px) {
        border-radius: 0;  
    }
}

.logo {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    span {
        font-size: 2.5rem;
        font-weight: 600;
        @media screen and (max-height: 768px) {
            font-size: 2rem;
        }
    }
}

.sub-logo {
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
    @media screen and (max-height: 768px) {
        font-size: 22px;
        line-height: 30px;
    }
}

a {
    text-decoration: none;
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    &:hover {
        text-decoration: underline;
    }
    &.green-link {
        color: #3f7250;
    }
}
ul {
    list-style-image: url("./icons/checkmark.svg");
    padding: 0 0 0 23px;
	align-items: baseline;
    li {
        &:not(:last-child) {
            margin-bottom: 6px;
        }
        a {
            font-size: 14px;
        }
    }
}

.products {
    span {
        font-size: 14px;
        font-weight: 700;
        line-height: 40px;
    }
}
.info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    span {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }
    a {
        font-size: 14px;
    }
}

.input-with-icon {
    position: relative;
}

.input-with-icon input {
    padding-left: 30px; /* Adjust the value as needed */
}

.input-with-icon .icon {
    position: absolute;
    left: 5px; /* Adjust the value as needed */
    top: 50%;
    transform: translateY(-50%);
}

.completed-page {
    display: flex;
    flex-direction: column;
    height: 70vh;
    align-items: center;
    justify-content: center;
}

.checkmark {
    background-image: url('./icons/checkmark-green.svg');
    background-size: cover;
    display: flex;
    width: 75px;
    height: 60px;
    align-items: center;
    justify-content: center;
}

.sd-progress-buttons__page-title {
    display: none   !important;
}

.sd-btn {
    border-radius: 100px !important;
    padding: 8px 24px !important;
}

#sv-nav-next {
    input {
        border: 1px solid;
        font-weight: 700;
    }
}

.about {
    font-size: 16px;
    line-height: 28px;
    padding: 0 40px 20px 40px;
    @media screen and (max-width: 904px) {
        padding: 0 0px 20px 0px;
    }
    @media screen and (max-width: 768px) {
        padding: 0 40px 20px 40px;
    }
    @media screen and (max-width: 600px) {
        padding: 0 0px 20px 0px;
    }
}

@media screen and (max-width: 768px) {
    .sd-action-bar {
        flex-direction: column-reverse;
    }
    
    #sv-nav-prev, 
    #sv-nav-next,
    #sv-nav-complete {
        width: 100%;
        &.one-btn {
            width: 85%;
            position: fixed;
            bottom: 10px;
        }
    }

    h4 {
        margin: 8px;
    }

    .question-root-custom {
        border: 2px solid #3f72501a;
        padding: 4px;
      }
}

#sq_143 {
    .sd-selectbase {
        column-count: 2;
    }
}